import cookies from 'js-cookie';
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {faHandsClapping, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormLeadModule from 'modules/FormLeadModule';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import numeral from 'numeral';
import 'numeral/locales/es';
import {useActions} from 'contexts/ActionsMadeContext';

import eventsService from 'services/events';
import {IS_PRODUCTION} from 'environment';

numeral.locale('es');

const landingDefinitions: Record<string, LandingDefinition> = {
  default: {
    headerImage: '/images/landing/header-4.jpg',
    title: <h1>Alquila tu Grin por 1, 2 o 3 meses</h1>,
    benefits: [
      {
        title: 'Ahorra dinero',
        description:
          'Chau gasolina, pasajes, estacionamiento, taxis y más. ¡Te imaginas ahorrar 50 soles al mes o más! 🤯 ',
        icon: '/images/landing/money.png',
      },
      {
        title: 'Olvídate del tráfico',
        icon: '/images/landing/reloj.png',
        description:
          '¡Adiós estrés! Alquila tu patín y muévete rápido, seguro y divertido. Nunca más en el tráfico por 1 hora, llega a tu destino en 30 min o menos*',
      },
      {
        title: 'Mantenimientos ilimitados',
        icon: '/images/landing/mantenimiento.png',
        description: 'Si algo falla ¡Te lo cambiamos!',
      },
      {
        title: 'Cuida el planeta',
        icon: '/images/landing/ecology.png',
        description: 'Reduce tu huella de CO2',
      },
      {
        title: 'Atención personalizada',
        icon: '/images/landing/phone.png',
        description: 'Con solo un click ',
      },
      {
        title: 'Scooters resistentes',
        icon: '/images/landing/scooter-2.png',
        description:
          'Llantas sólidas y piso reforzado, ideales para las ciclovías limeñas ',
      },
    ],
    plans: [
      {
        id: 'plan_1m',
        title: 'Mensual',
        whatsappMessage:
          'Hola Grin, quiero alquilar un scooter en plan mensual de S/ 139.90. ¿Me puedes dar más info?',
        description: (
          <div>
            <div>Ahórrate más de 50 soles al mes en transporte. **</div>
          </div>
        ),
        price: 139.9,
      },
      {
        id: 'plan_2m',
        title: 'Bimestral',
        whatsappMessage:
          'Hola Grin, quiero alquilar un scooter en plan bimestral de S/ 239.90. ¿Me puedes dar más info?',
        description: (
          <div>
            <div>
              Aquí te ahorras S/ 39.90 en comparación al plan mensual y te
              regalamos 5 días gratis
            </div>
          </div>
        ),
        price: 239.9,
      },
      {
        id: 'plan_3m',
        title: 'Trimestral',
        whatsappMessage:
          'Hola Grin, quiero alquilar un scooter en plan trimestral de S/ 329.90. ¿Me puedes dar más info?',
        description: (
          <div>
            <div>
              Aquí te ahorras S/ 89.70 en comparación al plan mensual y te
              regalamos 7 días gratis
            </div>
          </div>
        ),
        price: 329.9,
      },
    ],
  },
};
function LandingModule() {
  const {actions, addAction} = useActions();
  const [startTime] = useState(new Date().getTime());
  const [showForm, setShowForm] = useState(false);
  const [stage, setStage] = useState<string>('init');
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [createdLead, setCreatedLead] = useState<Lead>();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsQuery = Object.fromEntries(searchParams);
  const utmSource = (searchParamsQuery.utm_source ?? '').toLowerCase();
  const emojis: Record<string, string> = {
    meta: '💚',
    google: '🛴 🚀',
  };

  const useEmojiForWhatsApp = emojis[utmSource] ?? '';

  const source =
    searchParams && typeof searchParams.get('source') === 'string'
      ? (searchParams.get('source') as string)
      : 'direct';

  const {referrer} = document;

  const landingId =
    searchParams &&
    typeof searchParams.get('lid') === 'string' &&
    (searchParams.get('lid') as string) in landingDefinitions
      ? (searchParams.get('lid') as string)
      : 'default';

  if (landingId === null) {
    return null;
  }

  const landing: LandingDefinition = landingDefinitions[landingId];

  useEffect(() => {
    if (!selectedPlan) {
      return;
    }
    setSearchParams((prev) => {
      prev.set('plan', selectedPlan);
      return prev;
    });
  }, [selectedPlan]);

  useEffect(() => {
    setSearchParams((prev) => {
      if (stage !== 'init' && prev.get('status') !== stage) {
        addAction({
          action: 'changeToStage',
          stage,
        });
      }
      prev.set('status', stage ?? '');
      return prev;
    });
  }, [stage]);

  const getVisitorId = () => {
    const cVisitorId = cookies.get('__vid');
    let visitorId: number;
    if (cVisitorId) {
      visitorId = parseInt(cVisitorId, 10);
    } else {
      visitorId = Math.floor(Math.random() * 2147483647);
      cookies.set('__vid', String(visitorId), {expires: 28, path: ''});
    }
    return visitorId;
  };

  const storeVisitorEvent = async (
    event: string,
    data: Record<string, any>
  ) => {
    if (!IS_PRODUCTION) {
      return;
    }
    const payload = {
      event,
      item_type: 'visitor',
      user_id: 0,
      item_id: getVisitorId(),
      lat: 0,
      lng: 0,
      data,
    };
    await eventsService.create(payload);
  };
  useEffect(() => {
    addAction({
      action: 'init',
    });
    storeVisitorEvent('landing:init', {
      source,
      landingId,
      referrer,
      queryParams: Object.fromEntries(searchParams),
    });
  }, []);

  const onCreateLead = (lead: Lead) => {
    setCreatedLead(lead);
    setStage('complete');
    if (typeof window !== 'undefined') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-16533372114/kqXaCPHr0bQZENL53Ms9',
      });
    }
    storeVisitorEvent('landing:complete', {
      source,
      landingId,
      leadId: lead.id,
      referrer,
      queryParams: Object.fromEntries(searchParams),
    });
  };

  const selectPlan = async (plan: string) => {
    setSelectedPlan(plan);
    addAction({
      action: 'selectPlan',
      plan,
    });

    const action = 'whatsapp';
    if (action === 'whatsapp') {
      await storeVisitorEvent('landing:interested', {
        source,
        landingId,
        nextAction: 'WhatsApp',
        referrer,
        plan,
        queryParams: Object.fromEntries(searchParams),
      });

      document.location = `https://api.whatsapp.com/send/?phone=51964116143&text=${encodeURIComponent(
        `${
          landing.plans.find((p) => p.id === plan)?.whatsappMessage ?? ''
        } ${useEmojiForWhatsApp}`
      )}`;
    } else {
      storeVisitorEvent('landing:interested', {
        source,
        landingId,
        referrer,
        nextAction: 'showLeadForm',
        plan,
        queryParams: Object.fromEntries(searchParams),
      });

      setCreatedLead(undefined);
      setShowForm(true);
      addAction({
        action: 'showForm',
      });
      setStage('interested');
    }
  };

  const closeForm = () => {
    setSelectedPlan('');
    setShowForm(false);
    setStage('');
    addAction({
      action: 'closeForm',
    });
    storeVisitorEvent('landing:closeForm', {
      source,
      landingId,
      referrer,
      queryParams: Object.fromEntries(searchParams),
    });
  };

  if (!landing) {
    return null;
  }
  return (
    <div className='relative font-sec'>
      <div className='fixed bottom-4 right-4 z-[100] '>
        <a
          href={`https://api.whatsapp.com/send/?phone=51964116143&text=${encodeURI(
            `Hola revisé su web, ¿me pueden dar más información? ${useEmojiForWhatsApp}`
          )}`}
          target='_blank'
          rel='noreferrer'
          className='block bg-main-500 p-4 rounded-full shadow-lg border-2 border-white'
        >
          <FontAwesomeIcon icon={faWhatsapp} className='text-white text-4xl' />
        </a>
      </div>
      <div className='section'>
        <div className=''>
          <div>
            <div
              className='relative w-full sm:h-[70dvh] h-[60dvh] bg-cover bg-top '
              style={{
                backgroundImage: `linear-gradient(rgba(0,0,0,.25), rgba(0,0,0, .25)), url(${landing.headerImage})`,
              }}
            >
              <div className='bg-black p-2 absolute top-2 left-2 sm:p-4 sm:top-8 sm:left-8 rounded-full'>
                <img
                  src='/images/grin-logo.svg'
                  alt='Grin'
                  className='size-8 sm:size-16'
                />
              </div>

              <div
                className='
                absolute
                text-4xl font-main sm:text-8xl p-2
                bottom-0 left-0 right-0
                leading-[125%]
                bg-black text-white  text-center sm:p-4 mx-auto
              '
              >
                <div className='max-w-[60%] sm:max-w-[80%] mx-auto'>
                  {landing.title}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {landing.plans && landing.plans.length > 0 && (
        <div
          className='section bg-main-500 bg-cover bg-bottom pb-[100px]'
          style={{
            backgroundImage: 'url(/images/bg-plans.jpg)',
          }}
        >
          <div className='inner'>
            <div className='text-center'>
              <div
                className='title-2 text-center mb-12 bg-main-500 text-black inline-block p-4 mx-auto'
                style={{
                  transform: 'skew(-10deg)',
                }}
              >
                <h2
                  style={{
                    transform: 'skew(10deg)',
                  }}
                >
                  Chapa tu plan
                </h2>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-12 gap-y-16 justify-center  mb-16'>
                {landing.plans.map((plan) => (
                  <div className='rounded-lg bg-white shadow-xl relative pb-10 '>
                    <div className='font-normal font-main bg-black p-2 py-4  text-white mt-8 text-center text-6xl'>
                      {plan.title}
                    </div>
                    <div className='grid grid-cols-1 items-center my-16 sm:mt-4'>
                      <div className='text-center pb-8 sm:py-8  font-normal  flex justify-center items-start gap-2 font-main text-black'>
                        <span className='text-4xl mt-2 '>S./</span>
                        <div className='flex items-end '>
                          <span className='text-[6rem] sm:text-[7rem] leading-[6rem] font-normal'>
                            {numeral(Math.floor(plan.price)).format('0')}
                          </span>
                          <span className='text-4xl ml-[2px] mb-[5px]'>
                            .{parseInt(`${plan.price}`.split('.')[1], 10) * 10}
                          </span>
                        </div>
                      </div>
                      <div className='text-xl sm:text-2xl text-black px-8 leading-[125%] text-left sm:text-center font-sec font-light'>
                        {plan.description}
                      </div>
                    </div>
                    <div className='text-center absolute bottom-[-20px] left-0 right-0'>
                      <button
                        type='button'
                        className={`button button-inverse big mx-auto mt-10 bg-black !inline-block plan_button_${plan.id}`}
                        onClick={() => selectPlan(plan.id)}
                      >
                        <div className='flex gap-4 items-center'>
                          <FontAwesomeIcon
                            icon={faWhatsapp}
                            className='text-white text-4xl'
                          />
                          <span>Quiero alquilar</span>
                        </div>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {landing.benefits && landing.benefits.length > 0 && (
        <div
          className='section bg-auto bg-top'
          style={{
            backgroundImage:
              'linear-gradient(rgba(255,255,255,0), rgba(255, 255, 255, .15)), url(/images/bg-form.jpg)',
          }}
        >
          <div className='inner py-24'>
            <div className='text-center'>
              <div
                className='title-2 text-center mb-16 bg-main-500 text-black inline-block p-4 mx-auto'
                style={{
                  transform: 'skew(-10deg)',
                }}
              >
                <h2
                  style={{
                    transform: 'skew(10deg)',
                  }}
                >
                  Beneficios
                </h2>
              </div>
              <div className='grid grid-cols-2 sm:grid-cols-3 gap-8 gap-y-16'>
                {landing.benefits.map((benefit) => (
                  <div
                    key={benefit.title}
                    className='flex  flex-col text-center gap-4 sm:p-8 justify-center'
                  >
                    <div className='text-center'>
                      <img
                        src={benefit.icon}
                        className='bg-main-500 bg-opacity-20 p-4 rounded-2xl size-24 sm:size-24 inline-block mx-auto'
                        alt=''
                      />
                    </div>
                    <div className='flex-grow text-center'>
                      <div className='font-semibold font-sec text-black text-2xl'>
                        {benefit.title}
                      </div>
                      <div className='text-black mt-2 text-md font-sec'>
                        {benefit.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className='section bg-auto bg-top'
        style={{
          backgroundImage:
            'linear-gradient(rgba(255,255,255,0), rgba(255, 255, 255, .15)), url(/images/bg-form.jpg)',
        }}
      >
        <div className='inner'>
          <div className='text-center'>
            <div
              className='title-2 text-center mb-12 bg-main-500 text-black inline-block p-4 mx-auto'
              style={{
                transform: 'skew(-10deg)',
              }}
            >
              <h2
                style={{
                  transform: 'skew(10deg)',
                }}
              >
                ¡Sabías que!
              </h2>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-16 text-2xl mt-16'>
              <div>
                Con cualquiera de nuestros planes Grin4u vas a tener un scooter
                en tu casa como si fuera tuyo. 24/7
              </div>
              <div>Te enviamos tu scooter en menos de 48 horas.</div>
              <div>Te cambiamos el scooter ante cualquier falla.</div>
            </div>
          </div>
        </div>
      </div>

      {false &&
        landing !== null &&
        landing.plans &&
        landing?.plans &&
        landing.plans.length > 0 && (
          <div
            className='section bg-main-500 bg-cover bg-bottom pb-[100px]'
            style={{
              backgroundImage: 'url(/images/bg-plans.jpg)',
            }}
          >
            <div className='inner'>
              <div className='text-center'>
                <div
                  className='title-2 text-center mb-12 bg-main-500 text-black inline-block p-4 mx-auto'
                  style={{
                    transform: 'skew(-10deg)',
                  }}
                >
                  <h2
                    style={{
                      transform: 'skew(10deg)',
                    }}
                  >
                    Chapa tu plan
                  </h2>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-12 gap-y-16 justify-center  mb-16'>
                  {landing.plans.map((plan) => (
                    <div className='rounded-lg bg-white shadow-xl relative pb-10 '>
                      <div className='font-normal font-main bg-black p-2 py-4  text-white mt-8 text-center text-6xl'>
                        {plan.title}
                      </div>
                      <div className='grid grid-cols-1 items-center my-16 sm:mt-4'>
                        <div className='text-center pb-8 sm:py-8  font-normal  flex justify-center items-start gap-2 font-main text-black'>
                          <span className='text-4xl mt-2 '>S./</span>
                          <div className='flex items-end '>
                            <span className='text-[6rem] sm:text-[7rem] leading-[6rem] font-normal'>
                              {numeral(Math.floor(plan.price)).format('0')}
                            </span>
                            <span className='text-4xl ml-[2px] mb-[5px]'>
                              .
                              {parseInt(`${plan.price}`.split('.')[1], 10) * 10}
                            </span>
                          </div>
                        </div>
                        <div className='text-xl sm:text-2xl text-black px-8 leading-[125%] text-left sm:text-center font-sec font-light'>
                          {plan.description}
                        </div>
                      </div>
                      <div className='text-center absolute bottom-[-20px] left-0 right-0'>
                        <button
                          type='button'
                          className='button button-inverse big mx-auto mt-10 bg-black !inline-block '
                          onClick={() => selectPlan(plan.id)}
                        >
                          Quiero alquilar
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

      {showForm && (
        <div className=' fixed inset-0 bg-black bg-opacity-85 grid justify-center items-center'>
          <div
            className='bg-bottom w-[90vw] sm:w-auto bg-cover p-4 sm:p-8 rounded sm:rounded-lg relative z-200 h-[95dvh] sm:h-auto overflow-y-auto'
            style={{
              backgroundImage:
                'linear-gradient(rgba(255,255,255,0), rgba(255, 255, 255, .15)), url(/images/bg-form.jpg)',
            }}
          >
            <div className='absolute right-4 top-4'>
              <button type='button' onClick={closeForm}>
                <FontAwesomeIcon icon={faTimes} className='text-2xl' />
              </button>
            </div>
            <div className='inner sm:p-6 '>
              {createdLead ? (
                <div className='text-center py-20'>
                  <div className='flex gap-8 justify-center items-center mt-8'>
                    <FontAwesomeIcon
                      icon={faHandsClapping}
                      className='text-main-500  text-6xl'
                    />
                    <span className='text-6xl font-main font-normal'>
                      Gracias!
                    </span>
                  </div>
                  <div className='mt-12 text-xl'>
                    Te contactaremos en breve via WhatsApp
                  </div>
                </div>
              ) : (
                <>
                  <div className='mb-10'>
                    <div className='title-2 mt-8 text-center text-main-600'>
                      ¿Estás interesado?
                    </div>
                    <div className='subtitle-2 text-lg sm:text-xl -mt-4 mb-4 text-center'>
                      Déjanos tus datos y te contactaremos a la brevedad
                    </div>
                  </div>
                  <FormLeadModule
                    extraParams={{
                      referrer,
                      queryParams: Object.fromEntries(searchParams),
                      actions,
                    }}
                    startTime={startTime}
                    selectedPlan={selectedPlan ?? 'noplan'}
                    landingId={landingId}
                    onCreateLead={onCreateLead}
                    source={source}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className='section bg-white text-black p-8 pt-16'
        style={{
          backgroundImage:
            'linear-gradient(rgba(255,255,255,0), rgba(255, 255, 255, .15)), url(/images/bg-form.jpg)',
        }}
      >
        <div className='flex flex-col gap-4 font-light'>
          <div>
            * Calculado en base a una ruta de diaria ida y vuelta de transporte
            metropolitano y un bus alimentador en hora punta.
          </div>
          <div>
            ** Calculado en base a una ruta de Miraflores a San Miguel entre las
            6 y 7 pm.
          </div>
        </div>
      </div>
      <div
        className='section bg-white text-black p-8 pt-16'
        style={{
          backgroundImage:
            'linear-gradient(rgba(255,255,255,0), rgba(255, 255, 255, .15)), url(/images/bg-form.jpg)',
        }}
      >
        <div className='flex gap-4 items-center justify-between'>
          <div className='flex gap-4 items-center'>
            <a
              href='https://www.facebook.com/grinpe'
              target='_blank'
              rel='noreferrer'
            >
              <FontAwesomeIcon
                icon={faFacebook}
                fixedWidth
                className='text-4xl text-black hover:text-main-500'
              />
            </a>
            <a
              href='https://www.instagram.com/grin_peru'
              target='_blank'
              rel='noreferrer'
            >
              <FontAwesomeIcon
                icon={faInstagram}
                fixedWidth
                className='text-4xl text-black hover:text-main-500'
              />
            </a>
          </div>
          <div className='text-center text-md'>
            © Grin Perú 2024 Todos los derechos reservados
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingModule;
