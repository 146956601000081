import apiClient from "api";

import * as deviceData from "mobile-device-detect";

export const client = {
  create: async (payload: any): Promise<ServiceResponse<any>> => {
    const createPayload = {
      ...payload,
    };
    createPayload.data = {
      ...(createPayload.data || {}),
      _d: deviceData,
      created_from: "web",
      _screen: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };
    return apiClient.retrieve<any>({
      url: "/event",
      method: "post",
      payload: createPayload,
    });
  },
};

export default client;
