import {useUser} from "contexts/UserContext";
import useIsAuthenticated from "hooks/useIsAuthenticated";
import {useNavigate} from "react-router-dom";

function BlankLayout({children, auth = false}: LayoutProps) {
  const navigate = useNavigate();
  const {user} = useUser();
  const isAuthenticated = useIsAuthenticated(user, null, () => {
    if (auth) {
      navigate("/login");
    }
  });
  if (auth && (!user || !isAuthenticated)) {
    return null;
  }
  return children;
}

export default BlankLayout;
