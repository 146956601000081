import React from "react";
import "./globals.css";

import {useService} from "services/hooks/useService";
import usersService from "services/users";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import {UserProvider} from "contexts/UserContext";
import LoginModule from "modules/LoginModule";
import AuthLayout from "layouts/AuthLayout";
import BlankLayout from "layouts/BlankLayout";
import LandingModule from "modules/LandingModule";
import {ActionsProvider} from "contexts/ActionsMadeContext";

function App() {
  const {
    // status,
    error: userError,
    response: userResponse,
  } = useService<User>(async () => usersService.me(), true);

  /* if (status === "executing") {
    return (
      <div className='text-slate-500 text-center h-[100vh] grid justify-center items-center'>
        <div>Loading...</div>
      </div>
    );
  } */

  type Path = {
    path: string;
    element: React.ReactElement;
    layout?: React.ReactElement;
  };
  const paths: Path[] = [
    {
      path: "/",
      element: (
        <BlankLayout>
          <LandingModule />
        </BlankLayout>
      ),
    },
    {
      path: "/login",
      element: (
        <AuthLayout>
          <LoginModule />
        </AuthLayout>
      ),
    },
  ];

  const router = createBrowserRouter(
    paths.map((path) => ({
      path: path.path,
      element: path.element,
    }))
  );
  return (
    <ActionsProvider actions={[]}>
      <UserProvider user={userError ? undefined : userResponse}>
        <RouterProvider router={router} />
      </UserProvider>
    </ActionsProvider>
  );
}

export default App;
