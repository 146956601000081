import {useState} from "react"

export interface UseFormObjectReturn<Type> {
  fields: Type
  updateField: (field: string, value: any) => void
  setFields: (fields: Type) => void
}

export function useFormObject<T>(defaultObject: T): UseFormObjectReturn<T> {
  const [fields, setFields] = useState<T>(defaultObject)

  /* useEffect(() => {
    setFields(defaultObject);
  }, [defaultObject]); */

  const updateField = (field: string, value: any) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }))
  }
  return {fields, updateField, setFields}
}
