import {useUser} from "contexts/UserContext";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

function AuthLayout({children}: LayoutProps) {
  const navigate = useNavigate();
  const {user} = useUser();
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <div className='h-[100vh] items-center justify-center grid'>
      <div>{children}</div>
    </div>
  );
}

export default AuthLayout;
