import {useEffect, useState} from "react";

function useIsAuthenticated(
  user: User | undefined,
  onAuthenticated?: (() => void) | null,
  onNonAuthenticated?: (() => void) | null
): boolean | undefined {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  useEffect(() => {
    if (isAuthenticated === true && onAuthenticated) {
      onAuthenticated();
    }
    if (isAuthenticated === false && onNonAuthenticated) {
      onNonAuthenticated();
    }
  }, [isAuthenticated]);
  useEffect(() => {
    setIsAuthenticated(user !== undefined && !!user?.id);
  }, [user]);
  return isAuthenticated;
}

export default useIsAuthenticated;
