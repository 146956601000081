import apiClient from 'api';

export const client = {
  me: async (): Promise<ServiceResponse<User>> => apiClient.retrieve<User>({
    url: '/user/me',
    method: 'get',
  }),
  country: async (): Promise<ServiceResponse<Country>> => apiClient.retrieve<Country>({
    url: '/country/CL',
    method: 'get',
  })
};

export default client;