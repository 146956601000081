import apiClient from "api";

export const client = {
  create: async (payload: Lead): Promise<ServiceResponse<Lead>> => {
    const createPayload = {
      ...payload,
      created_from: "web",
    };
    return apiClient.retrieve<Lead>({
      url: "/lead/create",
      method: "post",
      payload: createPayload,
    });
  },
};

export default client;
