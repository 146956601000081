import React, {createContext, useContext, useState} from "react";

type ActionContextType = {
  actions: Action[] | undefined;
  setActions: (newAction: Action[] | undefined) => void;
  addAction: (action: Action) => void;
};

const ActionContext = createContext<ActionContextType | undefined>(undefined);

function ActionsProvider({
  children,
  actions,
}: {
  children: React.ReactElement;
  actions: Action[] | undefined;
}) {
  const [stateActions, setStateActions] = useState<Action[] | undefined>(
    actions
  );

  const addAction = (action: Action) => {
    const newAction = {
      ...action,
      createdAt: new Date().toISOString(),
    };
    setStateActions((prev) => [...(prev || []), newAction]);
  };

  const value = React.useMemo(
    () => ({
      actions: stateActions,
      setActions: setStateActions,
      addAction,
    }),
    [stateActions]
  );

  return (
    <ActionContext.Provider value={value}>{children}</ActionContext.Provider>
  );
}

const useActions = () => {
  const context = useContext(ActionContext);
  if (!context) {
    throw new Error("useAction must be used inside an ActionProvider");
  }
  return context;
};

export {ActionsProvider, useActions};
