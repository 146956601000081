export const IS_PRODUCTION = process.env.NODE_ENV === "production";
const USE_PROD_IN_DEV = true;

// eslint-disable-next-line no-nested-ternary
export const API_BASE_URL = USE_PROD_IN_DEV
  ? "https://api.grow.mobi"
  : IS_PRODUCTION
  ? "https://api.grow.mobi"
  : "http://localhost:40002";

export const IS_USING_PROD_IN_DEV = !IS_PRODUCTION && USE_PROD_IN_DEV;
