import * as deviceData from "mobile-device-detect";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {
  faCaretRight,
  faEnvelope,
  faSpinner,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFormObject} from "hooks/useFormObject";
import {useService} from "services/hooks/useService";
import leadsService from "services/leads";
import {z} from "zod";
import {useEffect, useState} from "react";

type Props = {
  extraParams?: Record<string, any>;
  landingId: string;
  startTime: number;
  selectedPlan: string;
  source: string;
  onCreateLead: (lead: Lead) => void;
};

const defaultLead: Lead = {
  phone: "",
  name: "",
  email: "",
  landingId: "",
  source: "",
  selectedPlan: "",
  extraParams: {},
};
let pidSendLead: number;
function FormLeadModule({
  landingId,
  startTime,
  source,
  selectedPlan,
  onCreateLead,
  extraParams = {},
}: Props) {
  const [isSaving, setIsSaving] = useState(false);
  const {fields, updateField} = useFormObject<Lead>(defaultLead);
  const sendLead = async (isComplete: boolean = false) => {
    setIsSaving(true);
    const totalTime = new Date().getTime() - startTime;
    const payload = {
      id: fields.id,
      complete: isComplete,
      phone: `+51${fields.phone
        .replace(/^(\+51)/, "")
        .replace(/[^0-9+]/g, "")
        .substring(0, 9)}`,
      email: fields.email,
      name: fields.name,
      landingId,
      source,
      selectedPlan,
      extraParams,
      _meta: {
        totalTime: Math.floor(totalTime / 1000),
        device: deviceData,
      },
    };
    const rsp = await leadsService.create(payload);
    if (rsp.status) {
      updateField("id", rsp.data.id);
      if (isComplete) {
        onCreateLead(rsp.data);
      }
    }
    setIsSaving(false);
    return rsp;
  };

  const sendLeadIfNotSaving = async (isComplete: boolean = false) => {
    if (isComplete === false && isSaving) {
      return undefined;
    }
    return sendLead(isComplete);
  };

  const debounceSendLead = () => {
    if (pidSendLead) {
      window.clearTimeout(pidSendLead);
    }
    pidSendLead = window.setTimeout(() => {
      sendLeadIfNotSaving();
    }, 1000);
  };

  useEffect(() => {
    if (fields.phone || fields.email || fields.name) {
      debounceSendLead();
    }
  }, [fields.phone, fields.email, fields.name]);

  const {
    status,
    error,
    loader: createLead,
  } = useService<Lead>(() => sendLead(true), false);

  const schemaForm = z.object({
    email: z.string().email(),
    name: z.string().min(3),
    phone: z
      .string()
      .regex(/^[0-9]{9}$/)
      .min(9)
      .max(9),
  });
  const enabledButton = schemaForm.safeParse(fields).success;
  return (
    <div>
      {error && <div className=''>{error}</div>}
      <form className='w-full flex flex-col gap-4'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <label htmlFor='phone' className='text-xl'>
            <div className='block mb-2 text-sm font-medium'>Tu Teléfono</div>
            <div className='relative'>
              <div className='absolute rounded-l-lg flex items-center p-4 pointer-events-none bg-slate-50 top-[1px] left-[1px] bottom-[1px] border-r border-main-500'>
                <FontAwesomeIcon icon={faWhatsapp} />
              </div>
              <div className='absolute flex items-center p-4 pointer-events-none top-[1px] left-[36px] bottom-[1px]'>
                <span className='ml-2'>+51 </span>
              </div>
              <input
                type='tel'
                maxLength={9}
                minLength={9}
                id='phone'
                value={fields.phone}
                onPaste={(e) => {
                  updateField(
                    "phone",
                    e.clipboardData
                      .getData("Text")
                      .replace(/^(\+51)/, "")
                      .replace(/[^0-9+]/g, "")
                      .substring(0, 9)
                  );
                }}
                onChange={(e) => {
                  updateField(
                    "phone",
                    e.target.value
                      .replace(/^(\+51)/, "")
                      .replace(/[^0-9+]/g, "")
                      .substring(0, 9)
                  );
                }}
                className='text-slate-600 border border-main-500 rounded-lg  block w-full  p-2.5  pl-[6.5rem] outline-none'
              />
            </div>
          </label>
          <label htmlFor='email' className='text-xl'>
            <div className='block mb-2 text-sm font-medium'>Tu Email</div>
            <div className='relative'>
              <div className='absolute rounded-l-lg flex items-center p-4 pointer-events-none bg-slate-50 top-[1px] left-[1px] bottom-[1px] border-r border-main-500'>
                <FontAwesomeIcon icon={faEnvelope} className='' />
              </div>
              <input
                type='email'
                id='email'
                value={fields.email}
                onChange={(e) => {
                  updateField("email", e.target.value);
                }}
                className='text-slate-600 border border-main-500  rounded-lg  block w-full p-2.5 pl-[4rem] outline-none'
              />
            </div>
          </label>
          <label htmlFor='name' className='text-xl md:col-span-2'>
            <div className='block mb-2 text-sm font-medium'>
              Tu Nombre y Apellido
            </div>
            <div className='relative'>
              <div className='absolute rounded-l-lg flex items-center p-4 pointer-events-none bg-slate-100 top-[1px] left-[1px] bottom-[1px] border-r border-main-500'>
                <FontAwesomeIcon icon={faUser} />
              </div>
              <input
                type='text'
                id='name'
                value={fields.name}
                onChange={(e) => {
                  updateField("name", e.target.value);
                }}
                className='text-slate-600 border border-main-500  rounded-lg  block w-full p-2.5  pl-[4rem] outline-none'
              />
            </div>
          </label>
        </div>
        <div
          className={`text-center mt-8 mb-4 ${
            !enabledButton ? "opacity-100" : "opacity-100"
          }`}
        >
          <button
            type='button'
            className='inline-block button big'
            disabled={!enabledButton}
            onClick={createLead}
          >
            {status === "executing" ? (
              <FontAwesomeIcon icon={faSpinner} spin className='text-white' />
            ) : (
              <div className='flex gap-4 items-center'>
                <span>Enviar</span>
                <FontAwesomeIcon
                  icon={faCaretRight}
                  className='text-2xl mt-1'
                />
              </div>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default FormLeadModule;
